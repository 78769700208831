import React, { useState } from 'react';
import Flex, { FlexItem } from 'styled-flex-component';

import { useTrackedState } from '../lib/state';

import SubLayout from '../components/SubLayout';
import { P } from '../components/common/P.style';
import { Img } from '../components/common/Img.style';
import Link from '../components/Link';
import { H2, H2Background, H3, H4 } from '../components/common/H.style';
import { UL } from '../components/common/UL.style';
import { LI } from '../components/common/LI.style';
import Anchor from '../components/Anchor';

import WeeklySchedule from '../components/WeeklySchedule';
import ClosedDayLabel from '../components/ClosedDayLabel';
import AccessInfoArea from '../components/AccessInfoArea';
import Modal from '../components/Modal';

import us1lg from '../assets/img/us-1-lg.png';

const About = () => {
  const { size } = useTrackedState();
  const [open, setOpen] = useState(false);
  return (
    <SubLayout topTitle="求人情報">
      <div>
        ヒラハタクリニックでは、現在以下の職種の募集を行っております。
        <br />
        求人に関するお問い合わせやご応募は{' '}
        <a href="mailto:keiri＠hirahata-clinic.or.jp">keiri@hirahata-clinic.or.jp</a>{' '}
        へお願いいたします。
        <Anchor id="jimu"></Anchor>
        <H3>医療事務（フルタイム）</H3>
        <div>
          <h4>【業務内容】</h4>
          <ul>
            <li>
              クリニックでの受付、会計、レセプト、電話対応、予約管理、健康診断に関する事務など
            </li>
          </ul>
          <h4>【勤務時間・曜日】</h4>
          <ol>
            <li>（1）8時45分 〜 17時45分</li>
            <li>（2）12時00分 〜 21時00分 </li>
          </ol>
          ※休憩時間60分 <br />
          (1)(2)のシフト制
          <ul>
            <li>
              （1）は8時30分、または9時00分、（2）は12時30分からの開始をお願いする日があります。
            </li>
            <li>木曜・土曜・日曜・祝日は１のシフトのみ</li>
          </ul>
          <h4>【休日休暇】 </h4>
          週休2日制（シフト制） <br />
          夏季休暇、年末年始休暇 <br />
          <h4>【給与】</h4>
          <ul>
            <li>基本給　270,000 円〜</li>
            <li>レセプト手当（最大2万円 /月）</li>
            <li>リーダー手当（最大5万円 /月）</li>
            <li>遅番勤務手当</li>
          </ul>
          ※残業代別途支給
          <br />
          ※試用期間3ヶ月（条件変更なし）
          <br />
          <h4>【待遇・福利厚生】</h4>
          <ul>
            <li>賞与あり（業績、規定による）</li>
            <li>賞与支給(規定あり)</li>
            <li>交通費支給（15,000円まで/月）</li>
            <li>制服貸与</li>
            <li>社会保険完備</li>
            <li>中小企業退職金共済加入</li>
            <li>定年60歳（再雇用65歳まで）</li>
          </ul>
          <h4>【応募条件・資格】</h4>
          <ul>
            <li>年齢・学歴・資格不問</li>
            <li>医療事務の経験がある方（レセプト経験は問いません）</li>
            <li>基本的なパソコン操作ができる方</li>
            <li>日曜・祝日勤務可能な方</li>
          </ul>
          詳細については　
          <a href="mailto:keiri＠hirahata-clinic.or.jp">keiri@hirahata-clinic.or.jp</a>
          　までお問い合わせください。
        </div>
        {/*  
        <H3>医療事務（パートタイム）</H3>
        <div>
          <h4>【業務内容】</h4>
          <ul>
            <li>受付・電話対応・予約管理、健康診断に関する事務など</li>
            <li>ダブルワークＯＫ</li>
            <li>
              内科、健康診断、人間ドックなど、働く人の健康管理を積極的に促進するクリニックです。
            </li>
          </ul>
          <h4>【勤務時間・曜日】</h4>
          <ul>
            <li>月・火・水・金・土8:45～21:00　木・日・祝日8:45～17:45</li>
            週3日以上勤務できる方。
            <br />
            1日4時間から応相談。
            <br />
          </ul>
          <h4>【給与】</h4>
          <ul>
            <li>時給　平日、土曜1,350円　日曜、祝日1,450円</li>
          </ul>
          <h4>【待遇・福利厚生】</h4>
          <ul>
            <li>賞与支給(規定あり)</li>
            <li>交通費1日1,000円まで支給</li>
            <li>制服貸与</li>
          </ul>
          <h4>【応募条件・資格】</h4>
          <ul>
            <li>年齢・学歴・資格不問</li>
            <li>医療事務未経験者も歓迎します</li>
          </ul>
          詳細については　
          <a href="mailto:keiri＠hirahata-clinic.or.jp">keiri@hirahata-clinic.or.jp</a>
          　までお問い合わせください。
        </div>
        */}
        <Anchor id="ns"></Anchor>
        <H3>看護師、准看護師(フルタイム)</H3>
        <div>
          <h4>【業務内容】</h4>
          <ul>
            <li>健康診断業務・点滴・外来業務・内視鏡介助など ※内視鏡介助は未経験可</li>
          </ul>
          <h4>【勤務時間・曜日】</h4>
          <ol>
            <li>（1）8時45分 〜 17時45分</li>
            <li>（2）12時00分 〜 21時00分</li>
          </ol>
          1、2のシフト制 <br />
          木曜・土曜・日曜・祝日は１のシフトのみ
          <br />
          ※休憩時間60分 <br />
          <h4>【給与】</h4>
          <ul>
            <li>基本給　300,000円～</li>
          </ul>
          ※試用期間3ヶ月（条件変更なし） <br />
          ※残業代別途支給 <br />
          <br />
          <h4>【休日休暇】</h4>
          <ul>
            <li>週休2日制</li>
            <li>夏季休暇、年末年始休暇</li>
          </ul>
          <h4>【待遇・福利厚生】</h4>
          <ul>
            <li>昇給あり</li>
            <li>賞与あり（業績、規定による）</li>
            <li>遅番勤務手当あり</li>
            <li>交通費支給（15,000円まで/月）</li>
            <li>制服貸与</li>
            <li>社会保険完備(法令遵守)</li>
            <li>中小企業退職金共済加入</li>
            <li>定年60歳（再雇用65歳まで）</li>
          </ul>
          <h4>【応募条件・資格】</h4>
          <ul>
            <li>看護師・准看護師</li>
            <li>看護師経験が１年以上ある方（内視鏡介助の経験は不問）</li>
            <li>日曜・祝日勤務可能な方</li>
          </ul>
          詳細については　
          <a href="mailto:keiri＠hirahata-clinic.or.jp">keiri@hirahata-clinic.or.jp</a>
          　までお問い合わせください。
        </div>
        <H3>看護師、准看護師(パートタイム)</H3>
        <div>
          <h4>【業務内容】</h4>
          <ul>
            <li>健康診断業務</li>
            <li>心電図、採血、血圧、眼底、脈波</li>
            <li>外来、点滴、内視鏡介助</li>
          </ul>
          <h4>【勤務時間・曜日】</h4>
          <ul>
            <li>(1)8:45～17:45（休憩60分）</li>
            <li>(2)12:00～21:00（休憩60分）</li>
            <li>(3)17:30～21:30（休憩なし）</li>
            <li>週2日～3日</li>
            <li>土日は(1)のシフトのみ</li>
          </ul>
          <h4>【給与】</h4>
          <ul>
            <li>正看護師：時給2,200円　准看護師：時給2,100円　※日曜は+100円</li>
          </ul>
          <h4>【待遇・福利厚生】</h4>
          <ul>
            <li>賞与あり（業績、規定による）</li>
            <li>交通費1日1,000円まで支給</li>
            <li>制服貸与</li>
          </ul>
          <h4>【応募条件・資格】</h4>
          <ul>
            <li>看證師免許・准看護師免許をお持ちの方</li>
            <li>看護師経験1年以上ある方</li>
            <li>土日どちらも勤務可能な方</li>
          </ul>
          詳細については　
          <a href="mailto:keiri＠hirahata-clinic.or.jp">keiri@hirahata-clinic.or.jp</a>
          　までお問い合わせください。
        </div>
        <Anchor id="shinkyu"></Anchor>
        {/* 
        <H3>鍼灸師・あんまマッサージ指圧師・理学療法士・柔道整復師（フルタイム）</H3>
        <div>
          <h4>【業務内容】</h4>
          問診、カルテ入力、簡単な物理療法（社内研修あり）
          <h4>【勤務時間・曜日】</h4>
          月～水、金：10:00～21:00 / 土日祝：10:00～19:00 <br />
          週5日、1日8時間のシフト制
          <br />
          ※休憩時間60分
          <h4>【休日休暇】</h4>
          週休2日制（木曜日・他1日）、夏季休暇、年末年始休暇
          <h4>【給与】</h4>
          <ul>
            <li>基本給　280,000円～</li>
          </ul>
          <br />
          　※残業代別途支給 <br />
          　※試用期間3ヶ月（条件変更なし）
          <h4>【待遇・福利厚生】</h4>
          <ul>
            <li>昇給あり</li>
            <li>賞与支給(規定あり)</li>
            <li>交通費支給（15,000円まで/月）</li>
            <li>制服貸与</li>
            <li>社会保険完備</li>
            <li>中小企業退職金共済加入</li>
            <li>定年60歳（再雇用65歳まで）</li>
          </ul>
          <h4>【応募条件・資格】</h4>
          <ul>
            <li>
              鍼灸師・あんまマッサージ指圧師・理学療法士・柔道整復師のいずれかの資格をお持ちの方
            </li>
            <li>日曜勤務可能な方歓迎</li>
          </ul>
          詳細については　keiri@hirahata-clinic.or.jp　までお問い合わせください。
        </div>
        <H3>鍼灸師・あんまマッサージ指圧師・理学療法士・柔道整復師（パートタイム）</H3>
        <div>
          <h4>【業務内容】</h4>
          問診、カルテ入力、簡単な物理療法（社内研修あり）
          <h4>【勤務時間・曜日】</h4>
          月～水、金：10:00～21:00 / 土日祝：10:00～17:00 <br />
          （勤務曜日、時間は応相談）
          <br />
          <h4>【給与】</h4>
          <ul>
            <li>時給1,600円～</li>
          </ul>
          <h4>【待遇・福利厚生】</h4>
          <ul>
            <li>賞与支給(規定あり)</li>
            <li>交通費1日1,000円まで支給</li>
            <li>制服貸与</li>
            <li>社会保険完備（週20時間以上の勤務で加入）</li>
          </ul>
          <h4>【応募条件・資格】</h4>
          <ul>
            <li>
              鍼灸師・あんまマッサージ指圧師・理学療法士・柔道整復師のいずれかの資格をお持ちの方
            </li>
            <li>日曜勤務可能な方歓迎</li>
            <li>雇用契約は3ヶ月毎の更新制になります。</li>
          </ul>
          詳細については　keiri@hirahata-clinic.or.jp　までお問い合わせください。
        </div>
        */}
        <Anchor id="eiyou"></Anchor>
        {/*
        <H3>管理栄養士（フルタイム）</H3>
        <div>
          <h4>【業務内容】</h4>
          問診、カルテ入力、簡単な物理療法（社内研修あり）、栄養指導
          <h4>【勤務時間・曜日】</h4>
          月～水、金：10:00～21:00 / 土日祝：10:00～19:00 <br />
          週5日、1日8時間のシフト制
          <br />
          ※休憩時間60分
          <h4>【休日休暇】</h4>
          週休2日制（木曜日・他1日）、夏季休暇、年末年始休暇
          <h4>【給与】</h4>
          <ul>
            <li>基本給　260,000円～</li>
          </ul>
          <br />
          　※残業代別途支給 <br />
          　※試用期間3ヶ月（条件変更なし）
          <h4>【待遇・福利厚生】</h4>
          <ul>
            <li>昇給あり</li>
            <li>賞与あり（業績、規定による）</li>
            <li>交通費支給（15,000円まで/月）</li>
            <li>制服貸与</li>
            <li>社会保険完備</li>
            <li>中小企業退職金共済加入</li>
            <li>定年60歳（再雇用65歳まで）</li>
          </ul>
          <h4>【応募条件・資格】</h4>
          <ul>
            <li>管理栄養士の資格をお持ちの方</li>
            <li>日曜勤務可能な方歓迎</li>
          </ul>
          詳細については　keiri@hirahata-clinic.or.jp　までお問い合わせください。
        </div>
        */}
      </div>
    </SubLayout>
  );
};

export default About;
